export default {
   // api: "http://192.168.20.206:8700/",
   // 线上
   //  api: " https://village.hemeixiangcun.xn--fiqs8s",
   // api: "http://192.168.2.9:8700",
   // api:'https://village.hemeixiangcun.xn--fiqs8s',
   //  api: "http://192.168.0.132:8087",
   // 测试地址
   //  api: "http://192.168.20.153:8700",
   //平江涛
   //  api: "http://121.40.130.95:9700",
   //陈州
   // api: "http://192.168.20.132:8700/",
   //张博文
   // api: "http://192.168.20.91:8700",
   //预发布地址
   // api: 'http://121.40.130.95:9700'
    api: 'https://test-village.hemeixiangcun.xn--fiqs8s'
   //  api: 'https://test-local-admin.hemeixiangcun.xn--fiqs8s'


   // 生产地址
   // api: "https://yuhou.aiteyixia.cn/"

}
