// import request from '../utils/requset';
import request from '../request/http';


const userlisttow = {
    // 乡村用户管理-用户列表
    villageBindUserList(params) {
        return request.get('/hemei-admin/villageBind/userList', { params })
    },
    //删除用户
    deleteUser(params) {
        return request.get('/hemei-admin/villageBind/deleteUser', { params })
    },
    //获取所有户主名称信息
    getHouseholdInfo(params) {
        return request.get('/hemei-admin/villageBind/getHouseholdInfo', { params })
    },
    //获取区域列表
    regionList(params) {
        return request.get('/hemei-admin/village/region/list', { params })
    },
    // 新增用户
    addUser(data) {
        return request.post('/hemei-admin/villageBind/addUser', data)
    },
    // 村民详情
    villagerDetails(params) {
        return request.get('/hemei-admin/villageBind/villagerDetails', { params })
    },
    // 用户转入/转出家庭
    intoFamily(params) {
        return request.get('/hemei-admin/villageBind/intoFamily', { params })
    },
    // 获取所有未加入家庭的成员信息
    memberList(params) {
        return request.get('/hemei-admin/villageBind/notJoined/memberList', { params })
    },
    // 修改村民信息
    updateUserInfo(data) {
        return request.post('/hemei-admin/villageBind/updateUserInfo', data)
    },
    // 民族列表
    nationList(params) {
        return request.get('/service-user/nation/list', { params })
    },
    // -新增用户(Excel导入)
    addUserinducts(data) {
        return request.post('/hemei-admin/villageBind/addUser/inducts', data)

    },
    // 根据乡村ID获取村庄所有未加入家庭的成员信息
    getNotJoined(params) {
        return request.get('/service-app/family/getNotJoined/memberList', { params })

    },
    // 获取某村庄下的用户信息
    getVillageUserList(params) {
        return request.get('/hemei-admin/user/getVillageUserList', { params })

    },

}

export default userlisttow;